var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('div',[_c('h3',[_vm._v("Productos que surtes...")])]),_c('p',[_vm._v("Buscar productos por nombre o código de barras")]),_c('searchbar-qrcode',{on:{"onChange":_vm.handleSearchbarChange}})],1),_c('b-table',{staticClass:"text-left",attrs:{"items":_vm.storeProducts,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('b-card-text',{staticClass:"font-weight-bold mb-25 d-flex"},[_c('b-img',{staticClass:"mr-1",attrs:{"width":"40px","height":"40px","src":("" + (data.item.logo)),"rounded":""}}),_c('div',[_vm._v(" "+_vm._s(data.item.product_attributes.name)+" "),_c('div',_vm._l((data.item.categories_names),function(category){return _c('b-badge',{key:("category-" + category),staticClass:"font-weight-bold mb-25 d-flex text-center",attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(category)+" ")])}),1)])],1)]}},{key:"cell(status)",fn:function(data){return [_c('app-timeline',[_c('app-timeline-item',{attrs:{"variant":Number(data.item.needs_attention) == 0
              ? 'danger'
              : Number(data.item.needs_attention) == 1
              ? 'warning'
              : 'success'}})],1)]}},{key:"cell(details)",fn:function(row){return [_c('b-button',{staticClass:"mr-2",attrs:{"size":"sm","variant":'primary'},on:{"click":row.toggleDetails}},[_vm._v(" "+_vm._s(row.detailsShowing ? "Ocultar" : "Ver")+" tiendas ")])]}},{key:"row-details",fn:function(data){return [_c('b-card',[(data.item && data.item.stores && data.item.stores.length > 0)?_c('b-table',{staticClass:"text-left",attrs:{"items":data.item.stores,"responsive":"","fields":_vm.tableColumnsForStoreProducts,"primary-key":"id","show-empty":"","empty-text":"No matching records found"},scopedSlots:_vm._u([{key:"cell(name)",fn:function(store){return [_c('b-card-text',{staticClass:"font-weight-bold mb-25 d-flex"},[_c('b-img',{staticClass:"mr-1",attrs:{"width":"40px","height":"40px","src":("" + (store.item.logo)),"rounded":""}}),_c('div',[_c('b-badge',{staticClass:"font-weight-bold mb-25 d-flex text-center",attrs:{"variant":"light-success"}},[_vm._v(" "+_vm._s(store.item.name)+" ")]),(store.item.address_attributes)?_c('div',[(
                      store.item.address_attributes.street ||
                      store.item.address_attributes.ext_number
                    )?_c('div',[_c('b-badge',{staticClass:"font-weight-bold mb-25 d-flex text-center",attrs:{"variant":"light-warning"}},[_vm._v(" "+_vm._s(store.item.address_attributes.street)+" "+_vm._s(store.item.address_attributes.ext_number)+", ")])],1):_vm._e(),(
                      store.item.address_attributes.postal_code ||
                      store.item.address_attributes.city
                    )?_c('div',[_c('b-badge',{staticClass:"font-weight-bold mb-25 d-flex text-center",attrs:{"variant":"light-warning"}},[_vm._v(" CP: "+_vm._s(store.item.address_attributes.postal_code)+", "+_vm._s(store.item.address_attributes.city)+", ")])],1):_vm._e(),(
                      store.item.address_attributes.state ||
                      store.item.address_attributes.country
                    )?_c('div',[_c('b-badge',{staticClass:"font-weight-bold mb-25 d-flex text-center",attrs:{"variant":"light-warning"}},[_vm._v(" "+_vm._s(store.item.address_attributes.state)+", "+_vm._s(store.item.address_attributes.country)+" ")])],1):_vm._e()]):_vm._e()],1)],1)]}},{key:"cell(contact)",fn:function(store){return [(store.item.cel_number)?_c('b-card-text',{staticClass:"font-weight-bold mb-25 d-flex"},[_c('b-badge',{staticClass:"font-weight-bold mb-25 d-flex text-center",attrs:{"variant":"light-primary"}},[_vm._v(" "+_vm._s(store.item)+" "+_vm._s(store.item.cel_number)+" ")])],1):_vm._e()]}},{key:"cell(inventory)",fn:function(store){return [_c('app-timeline',[_c('app-timeline-item',{attrs:{"variant":Number(store.item.needs_attention) == 0
                    ? 'danger'
                    : Number(store.item.needs_attention) == 1
                    ? 'warning'
                    : 'success'}},[(store.item.needs_attention === 0)?_c('b-badge',{attrs:{"variant":'danger'}},[_vm._v(" Sin existencias ")]):(store.item.needs_attention === 1)?_c('b-badge',{attrs:{"variant":'warning'}},[_vm._v(" Pocas existencias ")]):_c('b-badge',{attrs:{"variant":'success'}},[_vm._v(" Con existencias ")])],1)],1)]}}],null,true)}):_c('div',[_c('b-card-text',{staticClass:"font-weight-bold mb-25 d-flex"},[_c('b-badge',{staticClass:"font-weight-bold mb-25 d-flex text-center",attrs:{"variant":"light-danger"}},[_vm._v(" No hay tiendas a las que surtir ")])],1)],1)],1)]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('pagination',{attrs:{"loading":_vm.loading,"pagination":_vm.pagination,"entriesPerPage":_vm.entriesPerPage,"handlePagination":_vm.handlePagination},on:{"update:entriesPerPage":function($event){_vm.entriesPerPage=$event},"update:entries-per-page":function($event){_vm.entriesPerPage=$event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }